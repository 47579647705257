// Types
import { LOGGED_IN, LOADING } from "./types";
export const AuthenticationAction = (isAuthenticated, isTeacher) => ({
  /**
   * state: boolean
   */
  type: LOGGED_IN,
  payload: {
    isAuthenticated,
    isTeacher,
  },
});
export const LoadingAction = (loading) => ({
  type: LOADING,
  payload: {
    loading,
  },
});
