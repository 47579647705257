// Core
import React, { Component } from "react";

// Components
import {
  Catcher,
  OptionsStudnets,
  PresetFilters,
  Navbar,
  SiteFooter,
} from "../components";

export class Settings extends Component {
  render() {
    return (
      <div className="wrapper wrapper-grey">
        <Catcher>
          <Navbar />
          <div className="container-settings">
            <div className="row">
              <OptionsStudnets />
              <PresetFilters />
            </div>
          </div>
          <SiteFooter />
        </Catcher>
      </div>
    );
  }
}
