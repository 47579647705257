/**
 * API Configurations
 */

export const MAIN_URL = "https://321homeschool.com/api"; //`http://${
//  process.env.NODE_ENV !== "development"
//    ? "134.209.236.104:1337"
//    : "localhost:8000"
//}/api`;

export const CSRF_TOKEN = "x-csrftoken";
