// Core
import React, { Component } from "react";

// Components
import { Catcher, EditForm, Navbar, SiteFooter } from "../components";

export class StudentEdit extends Component {
  render() {
    return (
      <div className="wrapper wrapper-ligth-blue">
        <Catcher>
          <Navbar />
          <div className="container">
            <EditForm />
          </div>
          <SiteFooter />
        </Catcher>
      </div>
    );
  }
}
