// Core
import React, { Component } from "react";

// Instruments
import { getStudents } from "../../bus";
import { studentsRender } from "./dataHandlers";

export class MainStudents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      students: [],
    };
    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;
    this._isMounted && getStudents(this);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.taskList !== this.props.taskList ||
      prevProps.studentId !== this.props.studentId
    ) {
      const { taskList, studentId } = this.props;
      if (studentId !== null && taskList) {
        this.setState((prevState) => ({
          students: prevState.students.map((obj) =>
            parseInt(obj.id) === studentId
              ? Object.assign(obj, { subjects: taskList })
              : obj
          ),
        }));
      }
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { students } = this.state;
    return (
      <div className="students students__wrapper">
        <div className="students__row">
          <div className="students__title">
            <h1>Students</h1>
          </div>
          <ul className="students__list">{studentsRender(students)}</ul>
        </div>
      </div>
    );
  }
}
