// Core
import React, { Component } from "react";
import { connect } from "react-redux";

// Instruments
import {
  getDate,
  subjectsSelect,
  tasksSelect,
  getSelect,
} from "./dataHandlers";

// Actions
import { SetDailyFiltersAction } from "../../redux";

class DailyStatusFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this._isMounted = false;
  }
  componentDidMount = () => {
    getDate();
  };

  render() {
    const {
      subjects,
      tasks,
      setDailyFiltersDispatch,
      onDateChange,
    } = this.props;
    return (
      <div className="daily-filtres daily-filtres__wrapper">
        <div className="daily-filtres__row">
          <div className="daily-filtres__item daily-subject">
            <label htmlFor="Subject">Subject</label>
            <div className="select">
              <div className="select__header subject__header">
                <span className="select__current">All</span>
                <div
                  className="select__icon"
                  onClick={() =>
                    getSelect(
                      ".subject",
                      ".subjects",
                      setDailyFiltersDispatch,
                      ".subject__header",
                      "daily"
                    )
                  }
                >
                  <div className="icon"></div>
                </div>
              </div>
              <div className="select__body subject close-select init-select">
                {subjects !== null && subjects !== undefined
                  ? subjectsSelect(subjects)
                  : null}
              </div>
            </div>
          </div>
          <div className="daily-filtres__item date-start">
            <label htmlFor="">Assigned from</label>
            <label htmlFor="" className="daily-filtres__label-modile">
              From
            </label>
            <input
              id="date-start-input"
              type="date"
              defaultValue="2019-01-01"
              onChange={(e) => onDateChange(e.target.value, "start")}
            />
          </div>
          <div className="daily-filtres__item date-finish">
            <label htmlFor="">To</label>
            <input
              id="date-finish-input"
              type="date"
              onChange={(e) => onDateChange(e.target.value, "finish")}
            />
          </div>
          <div className="daily-filtres__item daily-tasks">
            <label htmlFor="Tasks">Tasks</label>
            <div className="select">
              <div className="select__header task__header">
                <span className="select__current">All</span>
                <div
                  className="select__icon"
                  onClick={() =>
                    getSelect(
                      ".task",
                      ".tasks",
                      setDailyFiltersDispatch,
                      ".task__header",
                      "daily"
                    )
                  }
                >
                  <div className="icon"></div>
                </div>
              </div>
              <div className="select__body task close-select init-select">
                {tasks !== null && tasks !== undefined
                  ? tasksSelect(tasks)
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Filters: state.Filters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDailyFiltersDispatch: (subject, task) =>
      dispatch(SetDailyFiltersAction(subject, task)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyStatusFilters);
