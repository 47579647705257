// Types
import { LOGGED_IN, LOADING } from "./types";

const initialState = {
  isAuthenticated: false,
  isTeacher: false,
  loading: false,
};

export function AuthenticationReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOGGED_IN:
      return { ...state, ...payload };

    case LOADING:
      return { ...state, ...payload };

    default:
      return state;
  }
}
