// Core
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Components
import { AttachmentsForm, DailyStatusFilters } from "../../components";

// Instruments
import {
  getDailySubjects,
  getDailyFilteredTasks,
  unassignTask,
  dailyCheckboxHandler,
} from "../../bus";
import { bodyOverflow } from "../";

// Actions
import { SetDailyFiltersAction } from "../../redux";

class TeacherDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dailyInfo: {
        subjects: [],
        tasks: [],
        students: [],
        showForm: false,
        chooseTask: "",
        display: false,
      },
    };

    this._isMounted = false;
  }

  componentDidMount = () => {
    const { setDailyFiltersDispatch } = this.props;

    this._isMounted = true;

    getDailySubjects(this).then(() => {
      const {
        dailyInfo: { subjects, tasks },
      } = this.state;
      setDailyFiltersDispatch(subjects[0], tasks[0]);

      // Initial dates
      const date1 = document.getElementById("date-start-input");
      const date2 = document.getElementById("date-finish-input");
      if (date1 !== null && date2 !== null) {
        getDailyFilteredTasks(
          subjects[0][0],
          tasks[0][0],
          date1.value,
          date2.value,
          this
        );
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    this._isMounted = true;
    const {
      Filters: {
        filtersDaily: { subject: dailySubject, task: dailyTask },
      },
    } = this.props;

    const {
      Filters: {
        filtersDaily: { subject: prevDailySubject, task: prevDailyTask },
      },
    } = prevProps;

    const {
      dailyInfo: { showForm },
    } = this.state;
    const {
      dailyInfo: { showForm: prevShowForm },
    } = prevState;

    // Initial dates
    const date1 = document.getElementById("date-start-input").value;
    const date2 = document.getElementById("date-finish-input").value;

    if (
      dailySubject !== undefined &&
      prevDailySubject !== undefined &&
      dailyTask !== undefined &&
      prevDailyTask !== undefined
    ) {
      if (
        dailySubject[0] !== prevDailySubject[0] ||
        dailyTask[0] !== prevDailyTask[0]
      ) {
        getDailyFilteredTasks(
          dailySubject[0],
          dailyTask[0],
          date1,
          date2,
          this
        );
      }
    }

    if (showForm !== prevShowForm) {
      getDailyFilteredTasks(dailySubject[0], dailyTask[0], date1, date2, this);
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  dateChanged = (date, type) => {
    const {
      Filters: {
        filtersDaily: { subject: dailySubject, task: dailyTask },
      },
    } = this.props;

    if (type === "finish") {
      this._isMounted = true;
      const dateStart = document.getElementById("date-start-input").value;
      getDailyFilteredTasks(
        dailySubject[0],
        dailyTask[0],
        dateStart,
        date,
        this
      );
    } else if (type === "start") {
      this._isMounted = true;
      const dateFinish = document.getElementById("date-finish-input").value;
      getDailyFilteredTasks(
        dailySubject[0],
        dailyTask[0],
        date,
        dateFinish,
        this
      );
    }
  };

  unassignTaskMethod = (subject, type, id, component) => {
    // Dates
    const date1 = document.getElementById("date-start-input").value;
    const date2 = document.getElementById("date-finish-input").value;

    unassignTask(subject, type, date1, date2, id, component);
  };

  dailyCheckboxHandlerMethod = (subject, task, taskId, e, component) => {
    // Dates
    const date1 = document.getElementById("date-start-input").value;
    const date2 = document.getElementById("date-finish-input").value;

    dailyCheckboxHandler(subject, task, date1, date2, e, taskId, component);
  };

  isShowForm = (isShow, id) => {
    /**
     * isShow: bool
     */
    this.setState((prevState) => ({
      dailyInfo: {
        ...prevState.dailyInfo,
        showForm: isShow,
        chooseTask: id,
      },
    }));

    bodyOverflow(isShow);
  };

  render() {
    const {
      dailyInfo: { subjects, tasks, students, showForm, chooseTask, display },
    } = this.state;

    const {
      isTeacher,
      Filters: {
        filtersDaily: { subject: dailySubject, task: dailyTask },
      },
    } = this.props;
    const studentsTasks = students.map((student) => {
      const { id: studentId, name, ...tasks } = student;
      return (
        <div key={studentId} className="dashboard-teacher__item">
          <div className="dashboard-teacher__students">
            <h1>{name}</h1>
          </div>
          {tasks.tasks.length !== 0 && tasks !== undefined && tasks !== null ? (
            <Fragment>
              {tasks.tasks.map((task) => {
                const {
                  id: taskId,
                  completed,
                  done,
                  file_count,
                  lore,
                  require,
                  subject,
                } = task;

                return (
                  <div
                    key={taskId}
                    className={
                      done
                        ? "dashboard-teacher__task-complete"
                        : "dashboard-teacher__task"
                    }
                  >
                    <div className="card__wrapper">
                      <div
                        className={
                          done
                            ? "card__header card__header-complete"
                            : "card__header"
                        }
                      >
                        <h1>{subject}</h1>
                      </div>
                      <div className="card__body">
                        <div className="card__describe">
                          <div
                            className="text-area"
                            readOnly
                            dangerouslySetInnerHTML={{ __html: lore }}
                            spellCheck="false"
                            placeholder="Enter a description"
                          />
                        </div>
                      </div>
                      <div className="card__footer">
                        {done ? (
                          <Fragment>
                            <div className="card__date">
                              <p>Completed at:</p>
                              <p>{completed}</p>
                            </div>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <div className="card__checkbox">
                              <input
                                value={require}
                                className="project-checkbox"
                                type="checkbox"
                                defaultChecked={require}
                                onChange={(e) =>
                                  this.dailyCheckboxHandlerMethod(
                                    dailySubject[0],
                                    dailyTask[0],
                                    taskId,
                                    e,
                                    this
                                  )
                                }
                              />
                              <p>Require upload</p>
                            </div>
                          </Fragment>
                        )}
                        <div className="card__control">
                          <div className="card__attachments">
                            <button
                              onClick={() => this.isShowForm(true, taskId)}
                            >
                              Attachments ({file_count})
                            </button>
                          </div>
                          <div className="card__return">
                            <button
                              onClick={() =>
                                this.unassignTaskMethod(
                                  dailySubject[0],
                                  dailyTask[0],
                                  taskId,
                                  this
                                )
                              }
                            >
                              Unassign
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Fragment>
          ) : null}
        </div>
      );
    });

    return (
      <Fragment>
        <DailyStatusFilters
          subjects={subjects}
          tasks={tasks}
          onDateChange={(date, type) => this.dateChanged(date, type)}
        />
        <div className="dashboard-teacher dashboard-teacher__wrapper">
          {showForm ? <div className="overlay"></div> : null}
          {showForm ? (
            <Fragment>
              <AttachmentsForm
                closeFormMethod={(isShow, id) => this.isShowForm(isShow, id)}
                chooseTask={chooseTask}
                isTeacher={isTeacher}
              />
            </Fragment>
          ) : null}
          <div className="dashboard-teacher__row">
            <div className="dashboard-teacher__desk">
              {!display ? (
                <Fragment>
                  <div className="dashboard-teacher-empty-list">
                    No tasks to display
                  </div>
                </Fragment>
              ) : (
                <Fragment>{studentsTasks}</Fragment>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  Filters: state.Filters,
  isTeacher: state.Authentication.isTeacher,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDailyFiltersDispatch: (subject, task) =>
      dispatch(SetDailyFiltersAction(subject, task)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherDashboard);
