// Core
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

// Root component
import App from "./navigation";

// Components
import { ScrollToTop } from "./components";

// Redux
import { store } from "./redux";

// Styles
import "./theme/sass/main.css";

const EntryPoint = (
  <Router>
    <Provider store={store}>
      <ScrollToTop />
      <App />
    </Provider>
  </Router>
);

ReactDOM.render(EntryPoint, document.getElementById("root"));
