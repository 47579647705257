// Types
import { SET_FILTERS, GET_FILTERS, SET_DAILY_FILTERS } from "./types";

export const SetFiltersAction = (grade, subject) => ({
  type: SET_FILTERS,
  payload: {
    grade,
    subject,
  },
});

export const SetDailyFiltersAction = (subject, task) => ({
  type: SET_DAILY_FILTERS,
  payload: {
    subject,
    task,
  },
});

export const GetFiltersAction = (filters) => ({
  type: GET_FILTERS,
  payload: {
    filters,
  },
});
