// Core
import React from "react";

// Instruments
import { setOptionsSubjectPreset, setOptionsGradePreset } from "../../bus";

let userPreset = {};
let dailyPreset = {};

const getOppositeFilter = (need, list) => {
  for (let item of list) {
    if (item.innerText === need) {
      return parseInt(item.getAttribute("value"));
    }
  }
};

export const getSelect = (
  classElement,
  elements,
  dispatchFunc,
  selectHeader,
  page
) => {
  const selectMenu = document.querySelector(classElement);
  const headerSelector = document.querySelector(selectHeader);
  const chooseElement = document.querySelectorAll(elements);

  const selectIsOpen = selectMenu.classList.contains("close-select");
  const selectIsInit = selectMenu.classList.contains("init-select");

  if (selectIsInit) {
    chooseElement.forEach((item) => {
      item.addEventListener("click", selectChoose, true);
    });
    selectMenu.classList.remove("init-select");
    document.addEventListener("click", (e) => {
      const isClickInsideBody = selectMenu.contains(e.target);
      const isClickInsideHeader = headerSelector.contains(e.target);
      if (!isClickInsideBody && !isClickInsideHeader) {
        selectMenu.classList.remove("open-select");
        selectMenu.classList.add("close-select");
      }
    });
  }

  function selectChoose() {
    const text = this.innerText,
      value = this.getAttribute("value"),
      select = this.closest(".select"),
      currentText = select.querySelector(".select__current");
    currentText.innerText = text;
    if (page === "main") {
      if (userPreset.touched) {
        userPreset = {};
        const curSub = document.querySelector(".subject-current").innerText;
        const curGrade = document.querySelector(".grade-current").innerText;

        if (curSub === "Select a Subject" || curGrade === "Select a Grade") {
          if (this.classList.contains("subjects")) {
            userPreset.subject = [parseInt(value), text];
          } else {
            userPreset.grade = [parseInt(value), text];
            dispatchFunc(userPreset.grade, [null, "Select a Subject"]);
          }
          if (Object.keys(userPreset).length === 2) {
            dispatchFunc(userPreset.grade, userPreset.subject);
          }
        } else {
          if (this.classList.contains("subjects")) {
            userPreset.subject = [parseInt(value), text];
            dispatchFunc([parseInt(curGrade), curGrade], userPreset.subject);
          } else {
            userPreset.grade = [parseInt(value), text];
            const allSubjects = document.querySelectorAll(".subjects");
            dispatchFunc(userPreset.grade, [
              getOppositeFilter(curSub, allSubjects),
              curSub,
            ]);
          }
        }
      } else {
        userPreset = {};
        const curSub = document.querySelector(".subject-current").innerText;
        const curGrade = document.querySelector(".grade-current").innerText;
        if (curSub === "Select a Subject" || curGrade === "Select a Grade") {
          if (this.classList.contains("subjects")) {
            userPreset.subject = [parseInt(value), text];
          } else {
            userPreset.grade = [parseInt(value), text];
            dispatchFunc(userPreset.grade, [null, "Select a Subject"]);
          }
          if (Object.keys(userPreset).length === 2) {
            dispatchFunc(userPreset.grade, userPreset.subject);
          }
        } else {
          if (this.classList.contains("subjects")) {
            userPreset.subject = [parseInt(value), text];
            dispatchFunc([parseInt(curGrade), curGrade], userPreset.subject);
          } else {
            userPreset.grade = [parseInt(value), text];
            const allSubjects = document.querySelectorAll(".subjects");
            dispatchFunc(userPreset.grade, [
              getOppositeFilter(curSub, allSubjects),
              curSub,
            ]);
          }
        }
      }
    } else if (page === "settings") {
      if (this.classList.contains("subjects")) {
        setOptionsSubjectPreset(parseInt(value));
        userPreset.touched = true;
      } else {
        setOptionsGradePreset(parseInt(value));
        userPreset.touched = true;
      }
    } else if (page === "daily") {
      if (this.classList.contains("subjects")) {
        dailyPreset.subject = [parseInt(value), text];

        const task = document.querySelector(".task__header .select__current")
          .innerText;
        const allTasks = document.querySelectorAll(".tasks");

        dailyPreset.task = [getOppositeFilter(task, allTasks), task];

        dispatchFunc(dailyPreset.subject, dailyPreset.task);
        dailyPreset = {};
      } else {
        dailyPreset.task = [parseInt(value), text];

        const subject = document.querySelector(
          ".subject__header .select__current"
        ).innerText;

        const allSubjects = document.querySelectorAll(".subjects");

        dailyPreset.subject = [
          getOppositeFilter(subject, allSubjects),
          subject,
        ];

        dispatchFunc(dailyPreset.subject, dailyPreset.task);
        dailyPreset = {};
      }
    }

    selectMenu.classList.remove("open-select");
    selectMenu.classList.add("close-select");
  }

  if (selectIsOpen) {
    selectMenu.classList.remove("close-select");
    selectMenu.classList.add("open-select");
  } else {
    selectMenu.classList.remove("open-select");
    selectMenu.classList.add("close-select");
  }
};

export const subjectsSelect = (subjects) => {
  let subjectItems = subjects.map((subject) => {
    return (
      <div
        id="daily-subject"
        key={subject[0]}
        value={subject[0]}
        className="select__item subjects select__item-mobile"
      >
        {subject[1]}
      </div>
    );
  });
  return subjectItems;
};

export const gradeSelect = (grades) => {
  let gradeItems = grades.map((grade) => {
    return (
      <div
        key={grade[0]}
        value={grade[0]}
        className="select__item grades select__item-mobile"
      >
        {grade[1]}
      </div>
    );
  });
  return gradeItems;
};

export const tasksSelect = (tasks) => {
  let taskItems = tasks.map((task) => {
    return (
      <div
        id="daily-task"
        key={task[0]}
        value={task[0]}
        className="select__item tasks"
      >
        {task[1]}
      </div>
    );
  });
  return taskItems;
};

export const subjectsOptions = (subjects) => {
  let subjectsOptionsItems = subjects.map((subject) => {
    return (
      <div
        key={subject[0]}
        value={subject[0]}
        className="select__item subjects"
      >
        {subject[1]}
      </div>
    );
  });
  return subjectsOptionsItems;
};

export const gradesOptions = (grades) => {
  let gradesOptionsItems = grades.map((grade) => {
    return (
      <div key={grade[0]} value={grade[0]} className="select__item grades">
        {grade[1]}
      </div>
    );
  });
  return gradesOptionsItems;
};

export const getDate = () => {
  document.getElementById("date-finish-input").valueAsDate = new Date();
};
