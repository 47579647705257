// Core
import React, { Component, Fragment } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";

// Components
import { MainFilters, MainStudents } from "../";

// Instruments
import {
  filteredTasks,
  defaultHandler,
  keyHandler,
  checkBoxHandler,
  deleteHandler,
  postponeHandler,
  assignTaskHandler,
  getFilters,
  combineTasks,
  defaultHandlerName,
  keyHandlerName,
} from "../../bus/";

// Actions
import { GetFiltersAction, SetFiltersAction } from "../../redux";

// Img
import trash from "../../theme/assets/images/trash.png";

class Projects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        tasks: [],
        assignSubjects: "",
        studentId: null,
        grade: null,
        subject: null,
        cardNameEdit: false,
        cardDescribeEdit: false,
      },
    };
    this._isMounted = false;
  }

  componentDidMount = () => {
    const { getFiltersDispatch, setFiltersDispatch } = this.props;
    const { subject, grade } = this.state;
    this._isMounted = true;

    getFilters(getFiltersDispatch, this).then(() => {
      if (
        subject !== null &&
        subject !== undefined &&
        grade !== null &&
        grade !== undefined
      ) {
        filteredTasks(this, grade[0], subject[0]);
        setFiltersDispatch(subject[0], grade[0]);
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      Filters: {
        filters: { subject, grade },
      },
    } = this.props;

    const {
      Filters: {
        filters: { subject: prevSubject, grade: prevGrade },
      },
    } = prevProps;

    if (subject[0] === null && grade !== prevGrade) {
      this._isMounted = true;
      filteredTasks(this, grade[0], -1);
    }

    if (
      subject !== null &&
      grade !== null &&
      (subject !== prevSubject || grade !== prevGrade)
    ) {
      this._isMounted = true;
      filteredTasks(this, grade[0], subject[0]);
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  onDragStart = (result) => {
    const { draggableId } = result;
    const dragTasks = document.querySelectorAll(".tasks__item");
    for (let item of dragTasks) {
      if (item.getAttribute("data-rbd-draggable-id") === draggableId) {
        let wrapper = item.childNodes;
        wrapper[0].childNodes[1].style.display = "none";
        wrapper[0].childNodes[2].style.display = "none";
      }
    }
  };

  onDragEnd = (result, subject, grade) => {
    const { source, destination, draggableId } = result;

    const dragTasks = document.querySelectorAll(".tasks__item");
    for (let item of dragTasks) {
      if (item.getAttribute("data-rbd-draggable-id") === draggableId) {
        let wrapper = item.childNodes;
        wrapper[0].childNodes[1].style.display = "";
        wrapper[0].childNodes[2].style.display = "";
      }
    }

    if (!destination) return;

    if (
      destination.droppableId &&
      destination.droppableId !== null &&
      destination.droppableId !== undefined &&
      destination.droppableId !== source.droppableId
    ) {
      this._isMounted = true;

      assignTaskHandler(
        grade[0],
        subject[0],
        draggableId,
        destination.droppableId,
        this
      );

      const taskSelector = `.pupil__task-${destination.droppableId}`;
      const studentsTasks = document.querySelector(taskSelector).innerText;
      combineTasks(
        {
          id: draggableId,
          old: studentsTasks,
        },
        destination.droppableId,
        this
      );
    }
  };

  isNameEdit = (id) => {
    this.setState((prevState) => ({
      info: {
        ...prevState.info,
        cardNameEdit: true,
      },
    }));
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 0);
  };

  cardBodyEdit = (id) => {
    this.setState((prevState) => ({
      info: {
        ...prevState.info,
        cardDescribeEdit: true,
      },
    }));
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 0);
  };

  render() {
    const {
      Filters: {
        filters: {
          subjects,
          grades,
          subject: filterSubject,
          grade: filterGrade,
        },
      },
    } = this.props;
    const {
      info: {
        tasks,
        assignSubjects,
        studentId,
        cardNameEdit,
        cardDescribeEdit,
      },
    } = this.state;
    const projectCards = tasks.map((task, index) => {
      const { subject, id, lore, require } = task;
      return (
        <Draggable key={id} draggableId={id} index={index}>
          {(provided, snapshot) => {
            return (
              <div
                className="tasks__item"
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={{
                  ...provided.draggableProps.style,
                  width: snapshot.isDragging ? "250px" : null,
                  width:
                    snapshot.isDragging && window.width === 770
                      ? "105px"
                      : null,
                  maxHeight: snapshot.isDragging ? "0px" : null,
                  height: snapshot.isDragging ? "0px" : null,
                  minHeight: snapshot.isDragging ? "0px" : null,
                  border: snapshot.isDragging ? "none" : null,
                }}
              >
                <div className="card card__wrapper">
                  <div className="card__header">
                    {cardNameEdit ? (
                      <Fragment>
                        <textarea
                          id={`card__name-edit-true-${id}`}
                          rows="1"
                          spellCheck="false"
                          onBlur={(e) =>
                            defaultHandlerName(
                              filterGrade[0],
                              filterSubject[0],
                              id,
                              e,
                              this
                            )
                          }
                          onKeyDown={(e) =>
                            keyHandlerName(
                              filterGrade[0],
                              filterSubject[0],
                              id,
                              e,
                              this
                            )
                          }
                        >
                          {subject}
                        </textarea>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div
                          className="card__name"
                          onClick={() =>
                            this.isNameEdit(`card__name-edit-true-${id}`)
                          }
                        >
                          {subject}
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <div className="card__body">
                    {cardDescribeEdit ? (
                      <Fragment>
                        <div className="card__describe ">
                          <div
                            id={`card-body-textarea-${id}`}
                            className="text-area"
                            contentEditable
                            spellCheck="false"
                            placeholder="Enter a description"
                            onBlur={(e) =>
                              defaultHandler(
                                filterGrade[0],
                                filterSubject[0],
                                id,
                                e,
                                null,
                                this
                              )
                            }
                            onKeyDown={(e) =>
                              keyHandler(
                                filterGrade[0],
                                filterSubject[0],
                                id,
                                e,
                                null,
                                this
                              )
                            }
                          >
                            {lore}
                          </div>
                        </div>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <div
                          className="card__describe "
                          onClick={() =>
                            this.cardBodyEdit(`card-body-textarea-${id}`)
                          }
                        >
                          <div style={{ cursor: "grab" }} className="text-area">
                            {lore}
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <div className="card__footer">
                    <div className="card__checkbox">
                      <input
                        value={require}
                        className="project-checkbox"
                        type="checkbox"
                        defaultChecked={require}
                        onChange={(e) =>
                          checkBoxHandler(
                            filterGrade[0],
                            filterSubject[0],
                            id,
                            null,
                            e,
                            this
                          )
                        }
                      />
                      <p>Require upload</p>
                    </div>
                    <div className="card__buttons">
                      <button
                        className="card__postpone"
                        onClick={() =>
                          postponeHandler(
                            filterGrade[0],
                            filterSubject[0],
                            id,
                            this
                          )
                        }
                      >
                        Postpone
                      </button>
                      <button className="card__delete">
                        <img
                          src={trash}
                          alt="trash"
                          onClick={() =>
                            deleteHandler(
                              filterGrade[0],
                              filterSubject[0],
                              id,
                              this
                            )
                          }
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        </Draggable>
      );
    });
    return (
      <DragDropContext
        onDragEnd={(result) =>
          this.onDragEnd(result, filterSubject, filterGrade)
        }
        onDragStart={(result) => {
          this.onDragStart(result);
        }}
      >
        <Droppable droppableId="cards">
          {(provided, snapshot) => {
            return (
              <Fragment>
                <MainStudents taskList={assignSubjects} studentId={studentId} />
                <div
                  className="project project__wrapper"
                  ref={provided.innerRef}
                >
                  <div className="project__row">
                    <MainFilters grades={grades} subjects={subjects} />
                    <div className="tasks tasks__wrapper">
                      <div className="tasks__bg">
                        <h1>Drag&Drop</h1>
                      </div>
                      {projectCards}
                    </div>
                  </div>
                  {provided.placeholder}
                </div>
              </Fragment>
            );
          }}
        </Droppable>
      </DragDropContext>
    );
  }
}

const mapStateToProps = (state) => ({
  Filters: state.Filters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFiltersDispatch: (filters) => dispatch(GetFiltersAction(filters)),
    setFiltersDispatch: (grade, subject) =>
      dispatch(SetFiltersAction(grade, subject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
