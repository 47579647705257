// Core
import { object, string, ref, boolean } from "yup";

export const login = {
  shape: {
    email: "",
    password: "",
  },
  schema: object().shape({
    email: string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email must be valid"
      )
      .required("this is a required field"),
    password: string().required("this is a required field"),
  }),
};

export const signUp = {
  shape: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    is_teacher: true,
  },
  schema: object().shape({
    first_name: string().required("this is a required field"),
    last_name: string().required("this is a required field"),
    email: string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email must be valid"
      )
      .required("this is a required field"),
    password: string().min(8).required("this is a required field"),
    confirm_password: string()
      .oneOf([ref("password"), null], "passwords must match")
      .required("this is a required field"),
    invite: boolean(),
  }),
};

export const studentSignUp = {
  shape: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    invite: true,
  },
  schema: object().shape({
    first_name: string().required("this is a required field"),
    last_name: string().required("this is a required field"),
    email: string()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "email must be valid"
      )
      .required("this is a required field"),
    password: string().min(8).required("this is a required field"),
    confirm_password: string()
      .oneOf([ref("password"), null], "passwords must match")
      .required("this is a required field"),
  }),
};

export const updateInfo = {
  schema: object().shape({
    first_name: string(),
    last_name: string(),
    email: string().matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "email must be valid"
    ),
  }),
};

export const updatePassword = {
  shape: {
    new_password: "",
    confirm_password: "",
    current_password: "",
  },
  schema: object().shape({
    new_password: string().min(8, "too short"),
    confirm_password: string()
      .required("this is a required field")
      .oneOf([ref("new_password"), null], "passwords must match"),
    current_password: string()
      .required("this is a required field")
      .min(8, "too short"),
  }),
};
