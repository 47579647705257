// Core

import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";

// Instruments
import { updateInfo, updatePassword } from "../../../bus/forms/shapes";
import { getProfileInfoTeacher, setProfileInfoTeacher } from "../../../bus";

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        first_name: "",
        last_name: "",
        email: "",
        exists: false,
        incorrect: false,
      },
    };
    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;
    getProfileInfoTeacher(this);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  _submitData = (credentials, { resetForm }) => {
    this._isMounted = true;
    setProfileInfoTeacher(this, credentials, resetForm);
  };

  render() {
    return (
      <Fragment>
        <div className="edit edit__wrapper">
          <div className="edit__row">
            <div className="edit__form">
              <div className="edit__title form-title">
                <h1>Edit information</h1>
              </div>
              <div className="update-info-form">
                <Formik
                  enableReinitialize
                  initialValues={this.state.info}
                  validationSchema={updateInfo.schema}
                  onSubmit={this._submitData}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="edit__group form-group">
                        <Field
                          className="edit__input"
                          name="first_name"
                          type="first_name"
                          placeholder="first name"
                        />
                        {errors.first_name && touched.first_name ? (
                          <div className="edit__error form-error">
                            {errors.first_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="edit__group form-group">
                        <Field
                          className="edit__input"
                          name="last_name"
                          type="last_name"
                          placeholder="last name"
                        />
                        {errors.last_name && touched.last_name ? (
                          <div className="edit__error form-error">
                            {errors.last_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="edit__group form-group">
                        <Field
                          className="edit__input"
                          name="email"
                          type="email"
                          placeholder="email"
                        />
                        {errors.email && touched.email ? (
                          <div className="edit__error form-error">
                            {errors.email}
                          </div>
                        ) : (
                          <Fragment>
                            <div className="edit__error email-error-edit not-exists">
                              user with this email already exists
                            </div>
                          </Fragment>
                        )}
                      </div>

                      <button
                        className="edit__button form-button"
                        type="submit"
                      >
                        Update
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="update-password-form">
                <Formik
                  enableReinitialize
                  initialValues={updatePassword.shape}
                  validationSchema={updatePassword.schema}
                  onSubmit={this._submitData}
                >
                  {({ errors, touched, isValid, dirty }) => (
                    <Form>
                      <div className="edit__group form-group">
                        <Field
                          className="edit__input"
                          name="new_password"
                          type="new_password"
                          placeholder="new password"
                        />
                        {errors.new_password && touched.new_password ? (
                          <div className="edit__error form-error">
                            {errors.new_password}
                          </div>
                        ) : null}
                      </div>
                      <div className="edit__group form-group">
                        <Field
                          className="edit__input"
                          name="confirm_password"
                          type="confirm_password"
                          placeholder="confirm password"
                        />
                        {errors.confirm_password && touched.confirm_password ? (
                          <div className="edit__error form-error">
                            {errors.confirm_password}
                          </div>
                        ) : null}
                      </div>
                      <div className="edit__group form-group">
                        <Field
                          className="edit__input"
                          name="current_password"
                          type="current_password"
                          placeholder="current password"
                        />
                        {errors.current_password && touched.current_password ? (
                          <div className="edit__error form-error">
                            {errors.current_password}
                          </div>
                        ) : (
                          <Fragment>
                            <div className="edit__error-password email-error-edit not-exists">
                              current password is incorrect
                            </div>
                          </Fragment>
                        )}
                      </div>

                      <button
                        disabled={!isValid || !dirty}
                        className="edit__button form-button"
                        type="submit"
                      >
                        Save password
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Profile;
