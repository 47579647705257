// Core
import React, { Component } from "react";

// Components
import { Catcher, StudentSignUpForm, Navbar, SiteFooter } from "../components";

export class StudentSignup extends Component {
  render() {
    return (
      <Catcher>
        <Navbar />
        <StudentSignUpForm />
        <SiteFooter />
      </Catcher>
    );
  }
}
