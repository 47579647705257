// Core
import React, { Component } from "react";
import { connect } from "react-redux";

// Instruments
import { getOptionsPreset } from "../../bus";
import { subjectsOptions, gradesOptions, getSelect } from "./dataHandlers";
import { MAIN_URL } from "../../REST";

// Actions
import { SetFiltersAction } from "../../redux";

export class PresetFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      grade: [],
      grades: [],
      subject: [],
      subjects: [],
    };
    this._isMounted = false;
  }
  componentDidMount = () => {
    this._isMounted = true;
    getOptionsPreset(this);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { grade, grades, subject, subjects } = this.state;
    const { setFiltersDispatch } = this.props;
    return (
      <div className="settings-filtres settings-filtres__wrapper">
        <div className="settings-filtres__title ">
          <h1>Filter presets</h1>
        </div>
        <div className="settings-filtres__item">
          <div className="select">
            <div className="select__header subject__header">
              <span className="select__current">
                {subject !== null && subject !== undefined
                  ? subject[1]
                  : "Select a Subject"}
              </span>

              <div
                className="select__icon"
                onClick={() =>
                  getSelect(
                    ".subject",
                    ".subjects",
                    setFiltersDispatch,
                    ".subject__header",
                    "settings"
                  )
                }
              >
                <div className="icon"></div>
              </div>
            </div>
            <div className="select__body subject close-select init-select">
              {subjects !== null && subjects !== undefined
                ? subjectsOptions(subjects)
                : null}
            </div>
          </div>
        </div>
        <div className="settings-filtres__item">
          <div className="select">
            <div className="select__header grade__header">
              <span className="select__current">
                {grade !== null && grade !== undefined
                  ? grade[1]
                  : "Select a Grade"}
              </span>
              <div
                className="select__icon"
                onClick={() =>
                  getSelect(
                    ".grade",
                    ".grades",
                    setFiltersDispatch,
                    ".grade__header",
                    "settings"
                  )
                }
              >
                <div className="icon"></div>
              </div>
            </div>
            <div className="select__body grade close-select init-select">
              {grades !== null && grades !== undefined
                ? gradesOptions(grades)
                : null}
            </div>
          </div>
        </div>
        <div className="settings-filtres__subtitle ">
          <h1>Reports</h1>
        </div>
        <a href={MAIN_URL + "/options/report/"} download>
          <button className="settings-filtres__report">Create a report</button>
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Filters: state.Filters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setFiltersDispatch: (grade, subject) =>
      dispatch(SetFiltersAction(grade, subject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PresetFilters);
