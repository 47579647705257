// Core
import React, { Component, Fragment } from "react";
import { AttachmentsForm, DailyStatusFilters } from "../../components/";
import { connect } from "react-redux";

// Instruments
import {
  getDailySubjects,
  getDailyFilteredTasks,
  completeTask,
  uncompleteTask,
} from "../../bus/";

// Actions
import { SetDailyFiltersAction } from "../../redux";

import { bodyOverflow } from "./dataHandlers";

class StudentDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dailyInfo: {
        subjects: [],
        tasks: [],
        students: [],
        showForm: false,
        chooseTask: "",
        display: false,
      },
    };
    this._isMounted = false;
  }

  componentDidMount = () => {
    const { setDailyFiltersDispatch } = this.props;

    this._isMounted = true;

    getDailySubjects(this).then(() => {
      const {
        dailyInfo: { subjects, tasks },
      } = this.state;
      setDailyFiltersDispatch(subjects[0], tasks[0]);

      // Initial dates
      const date1 = document.getElementById("date-start-input");
      const date2 = document.getElementById("date-finish-input");
      if (date1 !== null && date2 !== null) {
        getDailyFilteredTasks(
          subjects[0][0],
          tasks[0][0],
          date1.value,
          date2.value,
          this
        );
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    this._isMounted = true;
    const {
      Filters: {
        filtersDaily: { subject: dailySubject, task: dailyTask },
      },
    } = this.props;

    const {
      Filters: {
        filtersDaily: { subject: prevDailySubject, task: prevDailyTask },
      },
    } = prevProps;

    const {
      dailyInfo: { showForm },
    } = this.state;
    const {
      dailyInfo: { showForm: prevShowForm },
    } = prevState;

    // Initial dates
    const date1 = document.getElementById("date-start-input").value;
    const date2 = document.getElementById("date-finish-input").value;

    if (
      dailySubject !== undefined &&
      prevDailySubject !== undefined &&
      dailyTask !== undefined &&
      prevDailyTask !== undefined
    ) {
      if (
        dailySubject[0] !== prevDailySubject[0] ||
        dailyTask[0] !== prevDailyTask[0]
      ) {
        getDailyFilteredTasks(
          dailySubject[0],
          dailyTask[0],
          date1,
          date2,
          this
        );
      }
    }

    if (showForm !== prevShowForm) {
      getDailyFilteredTasks(dailySubject[0], dailyTask[0], date1, date2, this);
    }
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  dateChanged = (date, type) => {
    const {
      Filters: {
        filtersDaily: { subject: dailySubject, task: dailyTask },
      },
    } = this.props;

    if (type === "finish") {
      this._isMounted = true;
      const dateStart = document.getElementById("date-start-input").value;
      getDailyFilteredTasks(
        dailySubject[0],
        dailyTask[0],
        dateStart,
        date,
        this
      );
    } else if (type === "start") {
      this._isMounted = true;
      const dateFinish = document.getElementById("date-finish-input").value;
      getDailyFilteredTasks(
        dailySubject[0],
        dailyTask[0],
        date,
        dateFinish,
        this
      );
    }
  };

  completeTaskMethod = (subject, type, id, component) => {
    // Initial dates
    const date1 = document.getElementById("date-start-input").value;
    const date2 = document.getElementById("date-finish-input").value;

    completeTask(subject, type, date1, date2, id, component);
  };

  unmarkTaskMethod = (subject, type, id, component) => {
    // Initial dates
    const date1 = document.getElementById("date-start-input").value;
    const date2 = document.getElementById("date-finish-input").value;

    uncompleteTask(subject, type, date1, date2, id, component);
  };

  isShowForm = (isShow, id) => {
    /**
     * isShow: bool
     */
    this.setState((prevState) => ({
      dailyInfo: {
        ...prevState.dailyInfo,
        showForm: isShow,
        chooseTask: id,
      },
    }));

    bodyOverflow(isShow);
  };

  render() {
    const {
      dailyInfo: { subjects, tasks, students, showForm, chooseTask, display },
    } = this.state;

    const {
      Filters: {
        filtersDaily: { subject: dailySubject, task: dailyTask },
      },
    } = this.props;

    const studentsTasks = students.map((task) => {
      const {
        id,
        assigned,
        completed,
        done,
        file_count,
        lore,
        require,
        subject,
      } = task;
      return (
        <div
          key={id}
          className={`${done ? "content__item-complete" : "content__item"}`}
        >
          <div className="card card__wrapper">
            <div
              className={`${
                done ? "card__header card__header-complete" : "card__header"
              }`}
            >
              <h1>{subject}</h1>
            </div>
            <div className="card__body">
              <div className="card__describe">
                <div
                  className="text-area"
                  readOnly
                  dangerouslySetInnerHTML={{ __html: lore }}
                  spellCheck="false"
                  placeholder="Enter a description"
                />
              </div>
            </div>
            <div className="card__footer">
              <div className="card__date">
                {done ? (
                  <Fragment>
                    <p>Completed at:</p>
                    <p>{completed}</p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <p>Assigned at:</p>
                    <p>{assigned}</p>
                  </Fragment>
                )}
              </div>
              <div className="card__control">
                <div className="card__attachments">
                  <button onClick={() => this.isShowForm(true, id)}>
                    Attachments ({file_count})
                  </button>
                </div>
                {done ? (
                  <Fragment>
                    <div className="card__unmark">
                      <button
                        onClick={() => {
                          this.unmarkTaskMethod(
                            dailySubject[0],
                            dailyTask[0],
                            id,
                            this
                          );
                        }}
                      >
                        Unmark
                      </button>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="card__complete">
                      {require && file_count === 0 ? (
                        <button disabled>Complete</button>
                      ) : (
                        <button
                          onClick={() =>
                            this.completeTaskMethod(
                              dailySubject[0],
                              dailyTask[0],
                              id,
                              this
                            )
                          }
                        >
                          Complete
                        </button>
                      )}
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <Fragment>
        <DailyStatusFilters
          subjects={subjects}
          tasks={tasks}
          onDateChange={(date, type) => this.dateChanged(date, type)}
        />
        <div className="dashboard-students ">
          {showForm ? <div className="overlay"></div> : null}

          {showForm ? (
            <Fragment>
              <AttachmentsForm
                closeFormMethod={(isShow, id) => this.isShowForm(isShow, id)}
                chooseTask={chooseTask}
              />
            </Fragment>
          ) : null}
          <div className="dashboard-students__row">
            <div className="content content__wrapper">
              {!display ? (
                <Fragment>
                  <div className="dashboard-students-empty-list">
                    No tasks to display
                  </div>
                </Fragment>
              ) : (
                <Fragment>{studentsTasks}</Fragment>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  Filters: state.Filters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setDailyFiltersDispatch: (subject, task) =>
      dispatch(SetDailyFiltersAction(subject, task)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard);
