// Types
import { SET_FILTERS, GET_FILTERS, SET_DAILY_FILTERS } from "./types";

const initialState = {
  filters: {},
  filtersDaily: {
    subject: [],
    task: [],
  },
};

export function FilterReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_FILTERS:
      return { ...state, ...payload };

    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          subject: payload.subject,
          grade: payload.grade,
        },
      };

    case SET_DAILY_FILTERS:
      return {
        ...state,
        filtersDaily: {
          ...state.filtersDaily,
          subject: payload.subject,
          task: payload.task,
        },
      };

    default:
      return state;
  }
}
