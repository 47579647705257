export const book = {
  login: "/login",
  signup: "/signup",
  student_signup: "/signup/student",
  home: "/tasks/status",
  logout: "/logout",
  main: "/tasks/assign",
  settings: "/reports",
  settings_student_edit: "/reports/user/:id",
  profile: "/profile",
};
