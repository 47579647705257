import React, { Component, Fragment } from "react";
import { MAIN_URL } from "../../../REST";

// img
import exit from "../../../theme/assets/images/exit.png";
import trash from "../../../theme/assets/images/trash.png";
import download from "../../../theme/assets/images/download.png";

// Instruments
import { getAttachments, deleteAttachment, addAttachment } from "../../../bus";

export class AttachmentsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attachmentsInfo: {
        attachments: [],
      },
    };
    this._isMounted = false;
  }

  componentDidMount = () => {
    const { chooseTask } = this.props;
    this._isMounted = true;
    getAttachments(parseInt(chooseTask), this);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { closeFormMethod, chooseTask, isTeacher } = this.props;
    const {
      attachmentsInfo: { attachments },
    } = this.state;

    const userAttacments = attachments.map((attachment) => {
      const { id, name, url } = attachment;
      return (
        <div key={id} className="attachments__item">
          <div className="attachments__name">{name}</div>
          <div className="attachments__group">
            <button>
              <a href={MAIN_URL.slice(0, -4) + url} download>
                <img src={download} alt="" />
              </a>
            </button>
            <button>
              <img
                src={trash}
                alt=""
                onClick={() => deleteAttachment(chooseTask, id, this)}
              />
            </button>
          </div>
        </div>
      );
    });
    return (
      <div className="attachments attachments__wrapper">
        <div className="attachments__close">
          <img
            src={exit}
            alt=""
            onClick={() => closeFormMethod(false, chooseTask)}
          />
        </div>
        <div className="attachments__group">
          {userAttacments.length === 0 ? (
            <Fragment>
              <div className="attachments__empty">No attached files</div>
            </Fragment>
          ) : (
            userAttacments
          )}
        </div>
        {isTeacher ? null : (
          <Fragment>
            <div className="attachments__warning">
              Allowed extentions are: .docx, .jpeg, .png, .pdf
            </div>
          </Fragment>
        )}
        {isTeacher ? null : (
          <Fragment>
            <div className="attachments__button">
              <input
                id="input__file"
                type="file"
                accept="image/*"
                capture="camera"
                onChange={(e) =>
                  addAttachment(e.target.files[0], chooseTask, this)
                }
              />

              <label for="input__file">
                <p>Attach a file</p>
              </label>
              <input type="upload" id="download__file" />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
