// Core
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// Pages
import { Login, Signup } from "../pages";

// Instruments
import { book } from "./book";

export default class Public extends Component {
  render() {
    return (
      <Switch>
        <Route component={Login} path={book.login} />
        <Route exact component={Signup} path={book.signup} />
        <Redirect to={book.login} />
      </Switch>
    );
  }
}
