// Core
import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Instruments
import { signUp } from "../../../bus/forms/shapes";
import { _isAuthenticated } from "../../../bus";
import { buttonMessage } from "../../../instruments";
import { book } from "../../../navigation/book";
import { MAIN_URL, getData, postData } from "../../../REST";

// Action
import { AuthenticationAction, LoadingAction } from "../../../redux";

// Img
import logo from "../../../theme/assets/images/logo-text.png";

class SignUpForm extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
  }
  componentDidMount = () => {
    this._isMounted = true;
    this._isMounted &&
      getData(`${MAIN_URL}/users/get/`).then((body) => {
        _isAuthenticated(this.props, body, book.home);
      });
  };
  componentWillUnmount = () => {
    this._isMounted = false;
  };

  _submitSignUpForm = (credentials) => {
    this.props.loading(true);
    postData(`${MAIN_URL}/users/signup/`, JSON.stringify(credentials)).then(
      (body) => {
        if (body.status === "success") {
          _isAuthenticated(this.props, body, book.home);
        }
      }
    );
  };

  render() {
    const { loadingState } = this.props;
    return (
      <div className="teacher-login wrapper-ligth-blue">
        <div className="container">
          <div className="teacher-login__row">
            <div className="teacher-login__logo">
              <img className="" src={logo} alt="" />
            </div>
            <Formik
              initialValues={signUp.shape}
              validationSchema={signUp.schema}
              onSubmit={this._submitSignUpForm}
            >
              {({ errors, touched }) => (
                <Form className="teacher-login__form">
                  <div className="teacher-login__title form-title">
                    <h1>Sign Up</h1>
                  </div>
                  <div className="form-wrapper">
                    <div className="teacher-login__name">
                      <div className="teacher-login__group form-group">
                        <Field
                          className="teacher-login__input"
                          name="first_name"
                          type="text"
                          placeholder="first name"
                          autoComplete="off"
                        />
                        {errors.first_name && touched.first_name ? (
                          <div className="teacher-login__error form-error">
                            {errors.first_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="teacher-login__group form-group">
                        <Field
                          className="teacher-login__input"
                          name="last_name"
                          type="text"
                          placeholder="last name"
                          autoComplete="off"
                        />
                        {errors.last_name && touched.last_name ? (
                          <div className="teacher-login__error form-error">
                            {errors.last_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <Field
                        className="teacher-login__input"
                        name="email"
                        type="email"
                        placeholder="email"
                        autoComplete="off"
                      />
                      {errors.email && touched.email ? (
                        <div className="teacher-login__error form-error">
                          {errors.email}
                        </div>
                      ) : (
                        <div className="teacher-login__error form-error not-exists">
                          <span>user with this email already exists</span>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <Field
                        className="teacher-login__input"
                        name="password"
                        type="password"
                        placeholder="password"
                        autoComplete="off"
                      />
                      {errors.password && touched.password ? (
                        <div className="teacher-login__error form-error">
                          {errors.password}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <Field
                        className="teacher-login__input"
                        name="confirm_password"
                        type="password"
                        placeholder="confirm password"
                        autoComplete="off"
                      />
                      {errors.confirm_password && touched.confirm_password ? (
                        <div className="teacher-login__error form-error">
                          {errors.confirm_password}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <button className="teacher-login__button" type="submit">
                    {buttonMessage(loadingState, "Create account")}
                  </button>
                  <div className="teacher-login__subtitle form-subtitle">
                    <p>Already have an account?</p>
                    <NavLink to={book.login}>Sign In</NavLink>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.Authentication,
  loadingState: state.Authentication.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loggedIn: (isAuth, isTeacher) =>
      dispatch(AuthenticationAction(isAuth, isTeacher)),
    loading: (state) => dispatch(LoadingAction(state)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignUpForm)
);
