import React, { Component } from "react";

export class SiteFooter extends Component {
  render() {
    return (
      <div className="footer footer__wrapper">
        <div className="container">
          <div className="footer__row">
            <div className="footer__title">
              <h1>&copy; Copyright 321Homeschool.com 2020</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SiteFooter;
