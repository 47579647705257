// Core
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

// Reducers
import rootReducer from "./rootReducer";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const middlewares = [thunk];

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

const preloadedState = JSON.parse(localStorage.getItem("Authentication"));
const enhancedStore = composeEnhancers(applyMiddleware(...middlewares));

export const store = preloadedState
  ? createStore(rootReducer, preloadedState, enhancedStore)
  : createStore(rootReducer, enhancedStore);

store.subscribe(() => {
  // const state = store.getState();
  const Authentication = store.getState().Authentication;
  localStorage.setItem(
    "Authentication",
    JSON.stringify({ Authentication: Authentication })
  );
});
