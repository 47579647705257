// Core
import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";

// Instruments
import { login } from "../../../bus/forms/shapes";
import { _isAuthenticated } from "../../../bus";
import { buttonMessage } from "../../../instruments";
import { book } from "../../../navigation/book";
import { getData, postData, MAIN_URL } from "../../../REST";

// Action
import { AuthenticationAction, LoadingAction } from "../../../redux";

// Img
import logo from "../../../theme/assets/images/logo-text.png";

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;
    this._isMounted &&
      getData(`${MAIN_URL}/users/get/`).then((body) => {
        _isAuthenticated(this.props, body, book.home);
      });
  };

  _submitLoginForm = (credentials) => {
    this._isMounted = true;
    this.props.loading(true);
    this._isMounted &&
      postData(`${MAIN_URL}/users/login/`, JSON.stringify(credentials)).then(
        (body) => {
          _isAuthenticated(this.props, body, book.home, true);
        }
      );
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { loadingState } = this.props;
    return (
      <div className="login-form wrapper-ligth-blue">
        <div className="container">
          <div className="login-form__row">
            <div className="login-form__logo">
              <img src={logo} alt="" />
            </div>
            <Formik
              initialValues={login.shape}
              validationSchema={login.schema}
              onSubmit={this._submitLoginForm}
            >
              {({ errors, touched }) => (
                <Form className="login-form__form">
                  <div className="login-form__title form-title">
                    <h1>Sign In</h1>
                  </div>
                  <div className=" form-wrapper">
                    <div className="form-group">
                      <Field
                        className="login-form__input "
                        name="email"
                        type="email"
                        placeholder="email"
                      />
                      {errors.email && touched.email ? (
                        <div className="login-form__error form-error">
                          {errors.email}
                        </div>
                      ) : (
                        <div className="login-form__error form-error not-exists">
                          <span>entered email or password is incorrect</span>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <Field
                        className="login-form__input "
                        name="password"
                        type="password"
                        placeholder="password"
                      />
                      {errors.password && touched.password ? (
                        <div className="login-form__error form-error">
                          {errors.password}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <button
                    className="login-form__button form-button"
                    type="submit"
                  >
                    {buttonMessage(loadingState)}
                  </button>
                  <div className="login-form__subtitle form-subtitle">
                    <p>Are a teacher?</p>
                    <NavLink to={book.signup}>Sign Up</NavLink>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="login-form__instruction">
              <h2>
                Thank you for using 321Homeschool! This is designed to be a
                complete solution for parent/teacher- assigned, self-contained
                daily curriculum for homeschoolers. Our philosophy is to make
                schooling more like a real-life job situation. We allow parents
                to direct students in teaching themselves through common sense,
                internet research, and self-initiative. We encourage you to
                assign the projects and let your student figure out the details
                on their own. A few notes about the function: <br />
                <div className="login-form__list">
                  - You can choose projects in the “Create your own” category
                  and assign school-related or other projects to your student.
                  <br />
                  - You can “Edit” any project to customize it according to your
                  preferences. Just double-click on the assignment and edit the
                  text.
                  <br />
                  - You can “Postpone” any assignment and move it to the end of
                  the list of projects in that grade and subject.
                  <br />
                  - You can “Delete” projects that you don’t like and they will
                  disappear from the list forever.
                  <br />
                  - You can assign as many projects per subject as you would
                  like. While generally one project per subject per student is
                  ideal, you may prefer to assign multiple projects per day per
                  subject depending upon your student and the complexity of the
                  projects.
                  <br />
                </div>
                Please note that we are an aggregator of outside projects and
                the links within many of the projects will take you to projects
                that are not our property. Most are direct links though some may
                require a sign-up for a free account. Please let us know at
                <a href="mailto:help@321homeschool.com">
                  help@321homeschool.com
                </a>
                if you find unstable or inappropriate content within these
                links. <br />
                <p>Thanks for using 321Homeschool!</p>
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingState: state.Authentication.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loggedIn: (state, isTeacher) =>
      dispatch(AuthenticationAction(state, isTeacher)),
    loading: (state) => dispatch(LoadingAction(state)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
