// Core
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

// Components
import {
  Catcher,
  Navbar,
  TeacherDashboard,
  StudentDashboard,
  SiteFooter,
} from "../components";

class Home extends Component {
  render() {
    const { isTeacher } = this.props;
    return (
      <div className="wrapper wrapper-grey">
        <Catcher>
          <Navbar />
          <div className="container-teacher">
            {isTeacher ? (
              <Fragment>
                <TeacherDashboard />
              </Fragment>
            ) : (
              <Fragment>
                <StudentDashboard />
              </Fragment>
            )}
          </div>
          <SiteFooter />
        </Catcher>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isTeacher: state.Authentication.isTeacher,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
