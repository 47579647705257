// Core
import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Droppable } from "react-beautiful-dnd";

// Instruments
import { book } from "../../navigation/book";

export const studentsRender = (students) => {
  if (students.length <= 0) {
    return (
      <NavLink to={book.student_signup}>
        <button className="students-empty-button-main" type="submit">
          Add Students
        </button>
      </NavLink>
    );
  } else {
    const Students = students.map((student, index) => {
      const { name, id, subjects } = student;
      return (
        <Droppable droppableId={id} key={id}>
          {(provided, snapshot) => (
            <div className="">
              <li
                key={id}
                className="students__item"
                ref={provided.innerRef}
                style={{
                  background: snapshot.isDraggingOver ? "#517bdd" : "#5196dd",
                  // height: snapshot.isDraggingOver ? "100px" : null,
                }}
              >
                <div className="pupil pupil__wrapper">
                  <p className="pupil__name">
                    <h1>{name}</h1>
                  </p>
                  <Fragment>
                    <div className={`pupil__task pupil__task-${id}`}>
                      {subjects}
                    </div>
                  </Fragment>
                </div>
                {/* {provided.placeholder} */}
              </li>
            </div>
          )}
        </Droppable>
      );
    });
    return Students;
  }
};
