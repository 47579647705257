// Core
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";

// Img
import trash from "../../theme/assets/images/trash.png";
import pencil from "../../theme/assets/images/pencil.png";

// Instruments
import { getOptionsStudents, deleteOptionsStudent } from "../../bus";
import { book } from "../../navigation/book";

export class OptionsStudents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      students: [],
    };
    this._isMounted = false;
  }
  componentDidMount = () => {
    this._isMounted = true;
    getOptionsStudents(this);
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { students } = this.state;
    const studentList = students.map((student) => {
      const { id, name, email } = student;
      return (
        <li key={id} className="settings-student__item">
          <div className="settings-student__info">
            <div className="settings-student__name">
              <p>{name}</p>
            </div>
            <div className="settings-student__email">
              <p>{email}</p>
            </div>
          </div>
          <div className="settings-student__control">
            <div className="settings-student__edit">
              <NavLink to={`/reports/user/${id}`}>
                <img src={pencil} alt="" />
              </NavLink>
            </div>
            <div
              className="settings-student__delete"
              onClick={() => deleteOptionsStudent(id, this)}
            >
              <img src={trash} alt="" />
            </div>
          </div>
        </li>
      );
    });
    return (
      <div className="settings-student settings-student__wrapper">
        <div className="settings-student__title settings__title">
          <h1>Students</h1>
        </div>
        <ul className="settings-student__list">
          {studentList.length === 0 ? (
            <Fragment>
              <NavLink to={book.student_signup}>
                <button className="students-empty-button" type="submit">
                  Add Students
                </button>
              </NavLink>
            </Fragment>
          ) : (
            <Fragment>{studentList}</Fragment>
          )}
        </ul>
      </div>
    );
  }
}

export default withRouter(OptionsStudents);
