// Core
import React, { Component } from "react";

// Components
import { Catcher, SignUpForm } from "../components";

export class Signup extends Component {
  render() {
    return (
      <Catcher>
        <SignUpForm />
      </Catcher>
    );
  }
}
