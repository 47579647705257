// Core
import React, { Component, Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// Instruments
import { swapNav } from "../../instruments";
import { book } from "../../navigation/book";
import { logout } from "../../bus";
import { bodyOverflow } from "../";

// Action
import { AuthenticationAction } from "../../redux";

// Img
import close from "../../theme/assets/images/exit.png";
import logo from "../../theme/assets/images/logo.png";

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isBurgerShow: false,
    };

    this._isMounted = false;
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  _handleLogout = () => {
    this._isMounted = true;
    return this._isMounted && logout(this.props);
  };

  isBurgerShowMethod = (isShow) => {
    isShow ? swapNav("open") : swapNav("close");
    this.setState((prevState) => ({
      ...prevState,
      isBurgerShow: isShow,
    }));
    bodyOverflow(isShow);
  };

  render() {
    const { isTeacher } = this.props;
    const { isBurgerShow } = this.state;
    return (
      <div id="header" className="navbar wrapper-ligth-blue">
        {isBurgerShow ? <div className="overlay"></div> : null}
        <div className="container">
          <div className="navbar__row">
            <nav>
              <div className="navbar__wrapper">
                <img
                  src={logo}
                  alt="logo"
                  className="navbar__logo"
                  onClick={() => this.props.history.push(book.home)}
                />

                <ul className="navbar__list">
                  {isTeacher ? (
                    <Fragment>
                      <li className="navbar__item ">
                        <NavLink className="menu-item" to={book.main}>
                          Assign tasks
                        </NavLink>
                      </li>
                    </Fragment>
                  ) : null}
                  <li className="navbar__item ">
                    <NavLink className="menu-item" to={book.home}>
                      Tasks status
                    </NavLink>
                  </li>
                  {isTeacher ? (
                    <Fragment>
                      <li className="navbar__item ">
                        <NavLink className="menu-item" to={book.student_signup}>
                          Register a student
                        </NavLink>
                      </li>
                      <li className="navbar__item ">
                        <NavLink className="menu-item" to={book.settings}>
                          Reports
                        </NavLink>
                      </li>
                      <li className="navbar__item ">
                        <NavLink className="menu-item" to={book.profile}>
                          Profile
                        </NavLink>
                      </li>
                    </Fragment>
                  ) : null}
                  <li className="navbar__item menu-item">
                    <NavLink
                      className="menu-item"
                      to={book.logout}
                      onClick={this._handleLogout}
                    >
                      Logout
                    </NavLink>
                  </li>
                </ul>
                <button
                  className="navbar__burger navbar__burger-active open"
                  onClick={() => this.isBurgerShowMethod(true)}
                >
                  <div className="button-wrapper">
                    <div className=""></div>
                    <div className=""></div>
                    <div className=""></div>
                  </div>
                </button>
                <div className="burger">
                  <button
                    className="burger__close burger__close-active close"
                    onClick={() => this.isBurgerShowMethod(false)}
                  >
                    <img src={close} alt="close" />
                  </button>
                  <ul className="burger__list menu-item">
                    {isTeacher ? (
                      <Fragment>
                        <li className="burger__item">
                          <NavLink className="menu-item" to={book.main}>
                            Assign tasks
                          </NavLink>
                        </li>
                      </Fragment>
                    ) : null}
                    <li className="burger__item">
                      <NavLink className="menu-item" to={book.home}>
                        Tasks status
                      </NavLink>
                    </li>
                    {isTeacher ? (
                      <Fragment>
                        <li className="burger__item">
                          <NavLink
                            className="menu-item"
                            to={book.student_signup}
                          >
                            Register a student
                          </NavLink>
                        </li>
                        <li className="burger__item ">
                          <NavLink className="menu-item" to={book.settings}>
                            Reports
                          </NavLink>
                        </li>
                        <li className="burger__item ">
                          <NavLink className="menu-item" to={book.profile}>
                            Profile
                          </NavLink>
                        </li>
                      </Fragment>
                    ) : null}
                    <li className="burger__item menu-item">
                      <NavLink
                        className="menu-item"
                        to={book.logout}
                        onClick={this._handleLogout}
                      >
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isTeacher: state.Authentication.isTeacher,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loggedIn: (isAuth, isTeacher) =>
      dispatch(AuthenticationAction(isAuth, isTeacher)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar));
