// Core
import React, { Component } from "react";
import { connect } from "react-redux";

//Instruments
import { subjectsSelect, gradeSelect, getSelect } from "./dataHandlers";

// Actions
import { SetFiltersAction } from "../../redux";

class MainFilters extends Component {
  render() {
    const {
      grades,
      subjects,
      setFiltersDispatch,
      Filters: {
        filters: { subject, grade },
      },
    } = this.props;
    return (
      <div className="filters filters__wrapper filters__wrapper-mobile">
        <div className="filters__row">
          <div className="filters__item filters__item-mobile">
            <label htmlFor="Subject">Subject</label>
            <div className="select">
              <div className="select__header subject__header subject__header-mobile">
                <span className="subject-current select__current select__current-mobile">
                  {subject !== undefined
                    ? subject[0] !== null
                      ? subject[1]
                      : "Select a Subject"
                    : "Select a Subject"}
                </span>

                <div
                  className="select__icon select__icon-mobile"
                  onClick={() =>
                    getSelect(
                      ".subject",
                      ".subjects",
                      setFiltersDispatch,
                      ".subject__header",
                      "main"
                    )
                  }
                >
                  <div className="icon"></div>
                </div>
              </div>
              <div className="select__body subject close-select init-select">
                {subjects !== null && subjects !== undefined
                  ? subjectsSelect(subjects)
                  : null}
              </div>
            </div>
          </div>
          <div className="filters__item filters__item-mobile">
            <label htmlFor="grade">Grade</label>
            <div className="select">
              <div className="select__header grade__header grade__header-mobile">
                <span className="grade-current select__current select__current-mobile">
                  {grade !== undefined
                    ? grade[0] !== null
                      ? grade[1]
                      : "Select a Grade"
                    : "Select a Grade"}
                </span>
                <div
                  className="select__icon select__icon-mobile"
                  onClick={() =>
                    getSelect(
                      ".grade",
                      ".grades",
                      setFiltersDispatch,
                      ".grade__header",
                      "main"
                    )
                  }
                >
                  <div className="icon"></div>
                </div>
              </div>
              <div className="select__body grade close-select init-select">
                {grades !== null && grades !== undefined
                  ? gradeSelect(grades)
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  Filters: state.Filters,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setFiltersDispatch: (grade, subject) =>
      dispatch(SetFiltersAction(grade, subject)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainFilters);
