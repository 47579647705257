// Core
import React, { Component } from "react";

// Components
import { Catcher, Profile, Navbar, SiteFooter } from "../components";

export class TeacherProfile extends Component {
  render() {
    return (
      <Catcher>
        <Navbar />
        <Profile />
        <SiteFooter />
      </Catcher>
    );
  }
}
