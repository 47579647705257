import { getData, MAIN_URL } from "../../REST";

export const _isAuthenticated = (props, body, route, userSubmit, student) => {
  /**
   * props: object
   * body: object
   * route: string
   * userSubmit: boolean
   * student: boolean
   */
  const { status } = body;
  const { loggedIn, loading } = props;
  const { push } = props.history;
  const errorSelector = document.querySelector(".form-group .form-error");
  if (typeof loading === "function") loading(false);

  if (status === "success" && student) {
    // For Registrations form
    push(route);
    errorSelector.classList.add("not-exists");
    return true;
  } else if (status === "success") {
    getData(`${MAIN_URL}/users/get/`).then((response) => {
      let isTeacher = false;
      if (
        response.is_teacher !== undefined &&
        response.is_teacher !== null &&
        response.is_teacher !== false
      ) {
        isTeacher = true;
      } else isTeacher = false;
      if (response.status === "success") {
        loggedIn(true, isTeacher);
      } else {
        loggedIn(false, isTeacher);
      }
      push(route);
      return true;
    });
  }

  if (status === "exists" || (status === "fail" && userSubmit)) {
    errorSelector.classList.add("exists-error");
    errorSelector.classList.remove("not-exists");
    return false;
  }
};

export const alert = () => {
  let alertElement = document.getElementById("student-signup-alert");
  function elementShow() {
    alertElement.classList.add("in");
    if (alert !== undefined || alert !== null) {
      return setTimeout(() => {
        elementHide();
      }, 1500);
    }
  }
  function elementHide() {
    alertElement.classList.remove("in");
  }
  if (alert !== undefined || alert !== null) {
    return setTimeout(() => {
      elementShow();
    }, 1000);
  }
};
