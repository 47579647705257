// Core
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// Pages
import {
  StudentSignup,
  Home,
  Main,
  Settings,
  StudentEdit,
  TeacherProfile,
} from "../pages";

// Instruments
import { book } from "./book";

export default class Private extends Component {
  render() {
    const { isTeacher } = this.props;
    return (
      <Switch>
        {isTeacher ? (
          <Route component={StudentSignup} path={book.student_signup} />
        ) : null}
        <Route component={Home} path={book.home} />
        {isTeacher ? <Route component={Main} path={book.main} /> : null}
        {isTeacher ? (
          <Route component={Settings} exact path={book.settings} />
        ) : null}
        {isTeacher ? (
          <Route
            component={StudentEdit}
            exact
            path={book.settings_student_edit}
          />
        ) : null}
        {isTeacher ? (
          <Route component={TeacherProfile} path={book.profile} />
        ) : null}
        <Redirect to={book.home} />
      </Switch>
    );
  }
}
