import { getData, postData, MAIN_URL } from "../../REST";

export const logout = (props) => {
  const { loggedIn } = props;

  getData(`${MAIN_URL}/users/logout/`).then((body) => {
    if (body.status === "success") {
      loggedIn(false, false);
    }
  });
};

export const getStudents = (component) => {
  /**
   * component: object
   * component is `this` context prop
   */
  getData(`${MAIN_URL}/students/get/`).then((body) => {
    if (body.status === "success") {
      component.setState({
        students: body.students,
      });
    }
  });
};

export const filteredTasks = (component, grade, subject) => {
  /**
   * component: object
   * grade: string
   * subject: string
   * component is `this` context prop
   */
  if (grade !== null && subject !== null) {
    component._isMounted &&
      getData(
        `${MAIN_URL}/tasks/filter/?grade=${grade}&subject=${subject}`
      ).then((body) => {
        if (body.status === "success") {
          component.setState((prevState) => ({
            info: {
              ...prevState.info,
              tasks: body.tasks,
            },
          }));
        }
      });
  }
};

const editedTask = (grade, subject, id, value, require, component) => {
  component._isMounted &&
    getData(
      `${MAIN_URL}/tasks/edit/?grade=${grade}&subject=${subject}&id=${id}${
        value !== null ? `&value=${JSON.stringify(value)}` : ""
      }${require !== null ? `&require=${require}` : ""}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            tasks: body.tasks,
          },
        }));
      }
    });
};

export const keyHandler = (grade, subject, id, e, require, component) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    const element = document.querySelector(".card__describe");
    element.classList.add("enter");
    const value = e.target.innerText;
    editedTask(grade, subject, id, value, require, component);
    e.target.blur();
    component.setState((prevState) => ({
      info: {
        ...prevState.info,
        cardDescribeEdit: false,
      },
    }));
  }
};

export const checkBoxHandler = (grade, subject, id, value, e, component) => {
  const require = e.target.checked ? 1 : 0;
  editedTask(grade, subject, id, value, require, component);
};

export const defaultHandler = (grade, subject, id, e, require, component) => {
  const element = document.querySelector(".card__describe");
  if (element.classList.contains("enter")) {
    element.classList.remove("enter");
    return;
  }
  const value = e.target.innerText;
  editedTask(grade, subject, id, value, require, component);
  component.setState((prevState) => ({
    info: {
      ...prevState.info,
      cardDescribeEdit: false,
    },
  }));
};

export const deleteHandler = (grade, subject, id, component) => {
  component._isMounted &&
    getData(
      `${MAIN_URL}/tasks/delete/?grade=${grade}&subject=${subject}&id=${id}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            tasks: body.tasks,
          },
        }));
      }
    });
};

export const postponeHandler = (grade, subject, id, component) => {
  component._isMounted &&
    getData(
      `${MAIN_URL}/tasks/postpone/?grade=${grade}&subject=${subject}&id=${id}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            tasks: body.tasks,
          },
        }));
      }
    });
};

export const assignTaskHandler = (grade, subject, id, studentId, component) => {
  component._isMounted &&
    getData(
      `${MAIN_URL}/tasks/assign/?grade=${grade}&subject=${subject}&id=${id}&student_id=${studentId}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            tasks: body.tasks,
          },
        }));
      }
    });
};

export const getFilters = (dispatchFunc, component) => {
  if (component._isMounted) {
    return getData(`${MAIN_URL}/main/get/`).then((body) => {
      if (body.status === "success") {
        dispatchFunc(body);
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            grade: body.grade,
            subject: body.subject,
          },
        }));
      }
    });
  }
};

export const combineTasks = (dictionary, studentId, component) => {
  if (component._isMounted) {
    postData(`${MAIN_URL}/subjects/combine/`, JSON.stringify(dictionary)).then(
      (body) => {
        if (body.status === "success") {
          component.setState((prevState) => ({
            info: {
              ...prevState.info,
              assignSubjects: body.result,
              studentId: parseInt(studentId),
            },
          }));
        }
      }
    );
  }
};

export const getDailySubjects = (component) => {
  if (component._isMounted) {
    return getData(`${MAIN_URL}/daily/subjects/`).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          dailyInfo: {
            ...prevState.dailyInfo,
            subjects: body.subjects,
            tasks: body.tasks,
          },
        }));
      }
    });
  }
};

export const getDailyFilteredTasks = (
  subject,
  type,
  date1,
  date2,
  component
) => {
  if (component._isMounted) {
    return getData(
      `${MAIN_URL}/daily/filter/?subject=${subject}&type=${type}&date1=${date1}&date2=${date2}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          dailyInfo: {
            ...prevState.dailyInfo,
            students: body.result,
            display: body.display,
          },
        }));
      }
    });
  }
};

export const completeTask = (subject, type, date1, date2, id, component) => {
  if (component._isMounted) {
    return getData(
      `${MAIN_URL}/daily/complete/?subject=${subject}&type=${type}&date1=${date1}&date2=${date2}&id=${id}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          dailyInfo: {
            ...prevState.dailyInfo,
            students: body.result,
          },
        }));
      }
    });
  }
};

export const uncompleteTask = (subject, type, date1, date2, id, component) => {
  if (component._isMounted) {
    return getData(
      `${MAIN_URL}/daily/uncomplete/?subject=${subject}&type=${type}&date1=${date1}&date2=${date2}&id=${id}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          dailyInfo: {
            ...prevState.dailyInfo,
            students: body.result,
          },
        }));
      }
    });
  }
};

export const getAttachments = (id, component) => {
  if (component._isMounted) {
    return getData(`${MAIN_URL}/daily/attachments/get/?id=${id}`).then(
      (body) => {
        if (body.status === "success") {
          component.setState((prevState) => ({
            attachmentsInfo: {
              ...prevState.attachmentsInfo,
              attachments: body.attachments,
            },
          }));
        }
      }
    );
  }
};

export const deleteAttachment = (id, fid, component) => {
  if (component._isMounted) {
    return getData(
      `${MAIN_URL}/daily/attachments/delete/?id=${id}&fid=${fid}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          attachmentsInfo: {
            ...prevState.attachmentsInfo,
            attachments: body.attachments,
          },
        }));
      }
    });
  }
};

export const addAttachment = (object, id, component) => {
  if (object !== undefined && object !== null) {
    if (component._isMounted) {
      return postData(
        `${MAIN_URL}/daily/attachments/post/?id=${id}&name=${object.name}`,
        object
      ).then((body) => {
        if (body.status === "success") {
          component.setState((prevState) => ({
            attachmentsInfo: {
              ...prevState.attachmentsInfo,
              attachments: body.attachments,
            },
          }));
        }
      });
    }
  }
};

export const unassignTask = (subject, type, date1, date2, id, component) => {
  if (component._isMounted) {
    return getData(
      `${MAIN_URL}/daily/unassign/?subject=${subject}&type=${type}&date1=${date1}&date2=${date2}&id=${id}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          dailyInfo: {
            ...prevState.dailyInfo,
            students: body.result,
            display: body.display,
          },
        }));
      }
    });
  }
};

export const dailyCheckboxHandler = (
  subject,
  type,
  date1,
  date2,
  e,
  id,
  component
) => {
  const require = e.target.checked ? 1 : 0;
  if (component._isMounted) {
    return getData(
      `${MAIN_URL}/daily/edit/?subject=${subject}&type=${type}&date1=${date1}&date2=${date2}&id=${id}&require=${require}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          dailyInfo: {
            ...prevState.dailyInfo,
            students: body.result,
          },
        }));
      }
    });
  }
};

export const getStudentsLimit = (component) => {
  if (component._isMounted) {
    return getData(`${MAIN_URL}/users/count/`).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          ...prevState,
          studentsLimit: body.amount,
        }));
      }
    });
  }
};

export const getOptionsStudents = (component) => {
  if (component._isMounted) {
    return getData(`${MAIN_URL}/options/students/get/`).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          ...prevState,
          students: body.students,
        }));
      }
    });
  }
};

export const deleteOptionsStudent = (id, component) => {
  if (component._isMounted) {
    return getData(`${MAIN_URL}/options/students/delete/?id=${id}`).then(
      (body) => {
        if (body.status === "success") {
          component.setState((prevState) => ({
            ...prevState,
            students: body.students,
          }));
        }
      }
    );
  }
};

export const getOptionsPreset = (component) => {
  if (component._isMounted) {
    return getData(`${MAIN_URL}/options/presets/get/`).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          ...prevState,
          grade: body.grade,
          grades: body.grades,
          subject: body.subject,
          subjects: body.subjects,
        }));
      }
    });
  }
};

export const setOptionsGradePreset = (grade) => {
  return getData(`${MAIN_URL}/options/presets/set/?grade=${grade}`);
};

export const setOptionsSubjectPreset = (subject) => {
  return getData(`${MAIN_URL}/options/presets/set/?subject=${subject}`);
};

export const getProfileInfo = (component, id) => {
  if (component._isMounted) {
    return getData(`${MAIN_URL}/profile/get/?id=${id}`).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            email: body.info.email,
            last_name: body.info.last_name,
            first_name: body.info.first_name,
          },
        }));
      }
    });
  }
};

export const getProfileInfoTeacher = (component) => {
  if (component._isMounted) {
    return getData(`${MAIN_URL}/profile/get/`).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            email: body.info.email,
            last_name: body.info.last_name,
            first_name: body.info.first_name,
          },
        }));
      }
    });
  }
};

export const setProfileInfoTeacher = (component, credentials, resetForm) => {
  const editSelector = document.querySelector(".edit__error");
  const editSelectorPassword = document.querySelector(".edit__error-password");

  if (component._isMounted) {
    return postData(
      `${MAIN_URL}/profile/set/`,
      JSON.stringify(credentials)
    ).then((body) => {
      if (body.status === "success") {
        editSelector.classList.add("not-exists");
        editSelectorPassword.classList.add("not-exists");
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            email: body.info.email,
            last_name: body.info.last_name,
            first_name: body.info.first_name,
          },
        }));
        resetForm({ values: "" });
      }
      if (body.status === "exists") {
        editSelector.classList.add("exists-error");
        editSelector.classList.remove("not-exists");
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            exists: true,
          },
        }));
      }
      if (body.status === "incorrect_password") {
        editSelectorPassword.classList.add("exists-error");
        editSelectorPassword.classList.remove("not-exists");
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            incorrect: true,
          },
        }));
      }
    });
  }
};

export const setProfileInfo = (component, id, credentials, resetForm) => {
  const editSelector = document.querySelector(".edit__error");
  const editSelectorPassword = document.querySelector(".edit__error-password");

  if (component._isMounted) {
    return postData(
      `${MAIN_URL}/profile/set/?id=${id}`,
      JSON.stringify(credentials)
    ).then((body) => {
      if (body.status === "success") {
        editSelector.classList.add("not-exists");
        editSelectorPassword.classList.add("not-exists");
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            email: body.info.email,
            last_name: body.info.last_name,
            first_name: body.info.first_name,
          },
        }));
        resetForm({ values: "" });
      }
      if (body.status === "exists") {
        editSelector.classList.add("exists-error");
        editSelector.classList.remove("not-exists");
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            exists: true,
          },
        }));
      }
      if (body.status === "incorrect_password") {
        editSelectorPassword.classList.add("exists-error");
        editSelectorPassword.classList.remove("not-exists");
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            incorrect: true,
          },
        }));
      }
    });
  }
};

export const editedTaskName = (grade, subject, id, name, component) => {
  component._isMounted &&
    getData(
      `${MAIN_URL}/tasks/edit/?grade=${grade}&subject=${subject}&id=${id}&name=${name}`
    ).then((body) => {
      if (body.status === "success") {
        component.setState((prevState) => ({
          info: {
            ...prevState.info,
            tasks: body.tasks,
          },
        }));
      }
    });
};

export const keyHandlerName = (grade, subject, id, e, component) => {
  if (e.keyCode === 13) {
    e.preventDefault();
    const element = document.querySelector(".card__describe");
    element.classList.add("enter");
    const value = e.target.value;
    editedTaskName(grade, subject, id, value, component);
    e.target.blur();
    component.setState((prevState) => ({
      info: {
        ...prevState.info,
        cardNameEdit: false,
      },
    }));
  }
};

export const defaultHandlerName = (grade, subject, id, e, component) => {
  const element = document.querySelector(".card__describe");
  if (element.classList.contains("enter")) {
    element.classList.remove("enter");
    return;
  }
  const value = e.target.value;
  editedTaskName(grade, subject, id, value, component);
  component.setState((prevState) => ({
    info: {
      ...prevState.info,
      cardNameEdit: false,
    },
  }));
};
