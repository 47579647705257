// Core
import { combineReducers } from "redux";

// Reducers
import { AuthenticationReducer } from "../Authentication/reducer";
import { FilterReducer } from "../Filters/reducer";

export default combineReducers({
  Authentication: AuthenticationReducer,
  Filters: FilterReducer,
});
