// Core
import React, { Component } from "react";

// Components
import { Projects, Navbar, Catcher, SiteFooter } from "../components";

export class Main extends Component {
  render() {
    return (
      <div className="wrapper wrapper-grey">
        <Catcher>
          <Navbar />
          <div className="container-main">
            <div className="row">
              <Projects />
            </div>
          </div>
          <SiteFooter />
        </Catcher>
      </div>
    );
  }
}
