// Core
import React, { Component, Fragment } from "react";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Instruments
import { studentSignUp } from "../../../bus/forms/shapes";
import { buttonMessage } from "../../../instruments";
import { _isAuthenticated, alert } from "../../../bus";
import { book } from "../../../navigation/book";
import { MAIN_URL, postData } from "../../../REST";
import { getStudentsLimit } from "../../../bus";

// Action
import { AuthenticationAction, LoadingAction } from "../../../redux";

class StudentSignUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      studentsLimit: 0,
    };

    this._isMounted = false;
  }

  componentDidMount = () => {
    this._isMounted = true;
    getStudentsLimit(this);
  };

  _submitSignUpForm = (credentials, { resetForm }) => {
    this.props.loading(true);
    this._isMounted = true;
    this._isMounted &&
      postData(`${MAIN_URL}/users/signup/`, JSON.stringify(credentials)).then(
        (body) => {
          if (
            _isAuthenticated(this.props, body, book.student_signup, true, true)
          ) {
            resetForm({ values: "" });
            alert();
          }
        }
      );
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  render() {
    const { loadingState } = this.props;
    const { studentsLimit } = this.state;
    return (
      <div className="student-login wrapper-ligth-blue">
        <div className="container">
          <div id="student-signup-alert" className="fade alert">
            <span>Student registered successfully</span>
          </div>
          <div className="student-login__row">
            <Formik
              initialValues={studentSignUp.shape}
              validationSchema={studentSignUp.schema}
              onSubmit={this._submitSignUpForm}
            >
              {({ errors, touched }) => (
                <Form className="student-login__form">
                  <div className="student-login__title form-title">
                    <h1>Student registration</h1>
                  </div>
                  <div className="student-login__name">
                    <div className="student-login__group form-group">
                      <Field
                        className="student-login__input"
                        name="first_name"
                        type="text"
                        placeholder="first name"
                        autoComplete="off"
                      />
                      {errors.first_name && touched.first_name ? (
                        <div className="student-login__error form-error">
                          {errors.first_name}
                        </div>
                      ) : null}
                    </div>
                    <div className="student-login__group form-group">
                      <Field
                        className="student-login__input"
                        name="last_name"
                        type="text"
                        placeholder="last name"
                        autoComplete="off"
                      />
                      {errors.last_name && touched.last_name ? (
                        <div className="student-login__error form-error">
                          {errors.last_name}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <Field
                      className="student-login__input "
                      name="email"
                      type="email"
                      placeholder="email"
                      autoComplete="off"
                    />
                    {errors.email && touched.email ? (
                      <div className="student-login__error form-error">
                        {errors.email}
                      </div>
                    ) : (
                      <div className="student-login__error form-error not-exists">
                        <span>user with this email already exists</span>
                      </div>
                    )}
                  </div>
                  <div className="form-group">
                    <Field
                      className="student-login__input "
                      name="password"
                      type="password"
                      placeholder="password"
                      autoComplete="off"
                    />
                    {errors.password && touched.password ? (
                      <div className="student-login__error form-error">
                        {errors.password}
                      </div>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <Field
                      className="student-login__input "
                      name="confirm_password"
                      type="password"
                      placeholder="confirm password"
                      autoComplete="off"
                    />
                    {errors.confirm_password && touched.confirm_password ? (
                      <div className="student-login__error form-error">
                        {errors.confirm_password}
                      </div>
                    ) : null}
                  </div>
                  <div className="student-login__invite form-subtitle">
                    {studentsLimit >= 10 ? (
                      <Fragment>
                        <p>You can't invite more students</p>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Field name="invite" type="checkbox" />
                        <div className="student-login__subtitle ">
                          <p>Invite to my group</p>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <button className="student-login__button " type="submit">
                    {buttonMessage(loadingState, "Create account")}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingState: state.Authentication.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loggedIn: (isAuth, isTeacher) =>
      dispatch(AuthenticationAction(isAuth, isTeacher)),
    loading: (state) => dispatch(LoadingAction(state)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(StudentSignUpForm)
);
