// Core
import Cookies from "js-cookie";
import "whatwg-fetch";

// Instruments
import { CSRF_TOKEN } from "./config";

export async function getData(url) {
  /**
   * This function implements GET-request
   * and returned data will serializing to the json
   *
   * url: string
   */
  const response = await fetch(url, {
    credentials: "include",
  });
  return await response.json();
}

export async function postData(url, data = {}) {
  /**
   * This function implements POST-request
   * and sending data to the server.
   * Returned data will serializing to the json
   *
   * url: string
   * data: object
   */
  const csrftoken = Cookies.get(CSRF_TOKEN);
  const response = await fetch(url, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      "x-csrftoken": csrftoken,
    },
    body: data,
  });
  return await response.json();
}
