// Core
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Routes
import Private from "./Private";
import Public from "./Public";

class App extends Component {
  render() {
    const {
      isAuthenticated: { isAuthenticated, isTeacher },
    } = this.props;
    return isAuthenticated ? <Private isTeacher={isTeacher} /> : <Public />;
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.Authentication,
});

export default withRouter(connect(mapStateToProps, null)(App));
